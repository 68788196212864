<template>
  <div>
    <section id="login">
      <form ref="form" @submit.prevent="login">
        <v-container class="login_container">
          <v-row class="d-flex flex-column justify-center align-center">
            <h3 class="login-card__header">Sign in</h3>
            <s-card v-if="isVerifying" class="login-card col-12" outlined>
              <h3 class="login-card__header align-center text-center">
                Push notification sent
              </h3>
              <s-text
                weight="normal"
                color="grayTextAlt"
                class="align-center text-center mb-4"
              >
                On your mobile device, open the <b>ReachOut</b> app verification
                prompt, then tap <b>Approve login</b> to continue.
              </s-text>
              <div class="block_loader d-flex align-center justify-center">
                <v-progress-circular
                  indeterminate
                  class="ma-auto"
                  color="primary"
                  :size="20"
                  :width="3"
                />
              </div>
            </s-card>
            <div class="max-width" v-else>
              <s-card class="login-card col-12" outlined>
                <v-row>
                  <v-col>
                    <s-text-field
                      name="username"
                      label="Username (mobile or email)"
                      filled
                      required
                      v-model="username"
                      :error-messages="getErrors('username', $v.username)"
                      @blur="$v.username.$touch()"
                    />
                  </v-col>
                </v-row>

                <s-btn
                  testButton="submit-button"
                  type="submit"
                  color="primary"
                  class="ma-0"
                  elevation="0"
                  block
                  :loading="isLoading"
                  :disabled="$v.$invalid"
                >
                  Sign In
                </s-btn>
                <div>
                  <p class="pt-4 text-center">Or</p>
                  <s-btn
                    color="blue"
                    class="mb-3"
                    elevation="0"
                    block
                    @click="() => signInWithThirdParty('google')"
                  >
                    <v-icon class="mr-2">mdi-email</v-icon>
                    Continue with Gmail
                  </s-btn>
                  <s-btn
                    color="black"
                    class="mb-3"
                    elevation="0"
                    @click="() => signInWithThirdParty('github')"
                    block
                  >
                    <v-icon class="mr-2">mdi-github</v-icon>
                    Continue with Github
                  </s-btn>
                </div>
                <div class="mt-6 d-flex justify-center max-width">
                  Don't have an account?
                  <s-link
                    class="forgotpass-link pl-1"
                    :to="{ name: 'register' }"
                  >
                    Sign Up
                  </s-link>
                </div>
              </s-card>
              <div class="pt-3 max-width">
                <s-link
                  class="forgotpass-link"
                  :to="{ name: 'forgotPassword' }"
                >
                  Forgot your password?
                </s-link>
              </div>
            </div>
          </v-row>
        </v-container>
      </form>
    </section>
  </div>
</template>

<script>
import { SimpleEventBus } from '@/services/core/EventBus'
import { loginIOKey } from '@/utils/ioConstants'
import getErrors from '@/mixins/vuelidateErrors'
import { required, minLength } from 'vuelidate/lib/validators'
export default {
  mixins: [getErrors],
  name: 'Login',
  data() {
    return {
      username: '',
      isLoading: false,
      isWaitingForVerification: false,
      isVerified: false,
    }
  },
  validations: {
    username: { required, minLength: minLength(2) },
  },
  computed: {
    isVerifying() {
      return this.isLoading && this.isWaitingForVerification
    },
  },
  created() {
    SimpleEventBus.$on(loginIOKey, (data) => {
      if (data === 'opened') return
      if (data === 'closed') {
        this.resetUI()
        return
      }
      if (!data || !data.jwt || data.jwt === '') {
        this.$store.dispatch('ui/showError', {
          title: 'Unable to complete login!',
          description: 'Login failed. Please try again',
        })
        this.resetUI()
        return
      }
      localStorage.setItem('jwt', data.jwt)
      this.isVerified = true
      this.$router.push({ name: 'dashboard' })
    })
  },
  methods: {
    signInWithThirdParty(type) {
      const extraQuery =
        process.env.VUE_APP_ENV === 'local'
          ? '?redirectUrl=http://localhost:8080/auth/callback'
          : ''
      const url = `${process.env.VUE_APP_API_DOMAIN}/v1/auth/${type}${extraQuery}`
      window.open(url, '_self')
    },
    resetUI() {
      this.isLoading = false
      this.isWaitingForVerification = false
    },
    login() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      this.isLoading = true
      const payload = {
        username: this.username,
      }
      this.$store
        .dispatch('auth/initLogin', payload)
        .then((resp) => {
          // start websocket
          this.$store
            .dispatch('auth/initLoginCompleteWebsocket')
            .then((socket) => {
              // send verification id
              socket.queue(resp.verification_id)
            })
          this.isWaitingForVerification = true
        })
        .catch(() => {
          this.isLoading = false
        })
    },
    destroyed() {
      // remove listener
      // SimpleEventBus.$off(authStore.ioKeys.auth_login)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';
#login {
  @include center-children;

  & .login_container {
    @include auth-card-dimension;
  }
}

#logo {
  @include auth-logo;
}

.login-card {
  @include auth-card;
}

.signup-link {
  margin-top: 18px;
}

.forgotpass-link {
  @include black-link;
}
.login-card__header {
  @include auth-header;
}
</style>
